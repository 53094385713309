

























































import { Component, Prop, Watch } from 'vue-property-decorator';
import { deepGet } from '@/utils/ObjectHelpers';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import ButtonComponent from '@/components/ButtonComponent.vue';
import TitleComponent from '@/components/TitleComponent.vue';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';

@Component({
  components: {
    ButtonComponent,
    TitleComponent,
  },
  inheritAttrs: false,
})
export default class StaticHtmlWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  @Prop({
    required: false,
    default: 'title1',
  })
  readonly titleSize!: string;

  protected WidgetTitleGap: Record<string, string> = {
    NONE: '',
    QUARTER: 'mb-4',
    HALF: 'mb-40 mb-xl-5',
    FULL: 'mb-40 mb-md-80 mb-xl-6',
  };

  @Prop({
    required: false,
    default: null,
  })
  private readonly title!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly subtitle!: string;

  @Prop({
    required: false,
    default: 'HALF',
  })
  private readonly titleGap!: string;

  @Prop({
    required: false,
    default: false,
  })
  private readonly allowScript!: boolean;

  @Prop({
    required: false,
    default: null,
  })
  private readonly html!: string;

  @Prop({
    required: false,
    default: false,
  })
  private readonly datasource!: boolean;

  @Prop({ default: 'contained' })
  private readonly layout!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly data!: Record<string, string>;

  @Prop({
    required: false,
    default: null,
  })
  private readonly btnTitle!: string;

  @Prop({
    required: false,
    default: null,
  })
  private readonly btnRoute!: string;

  @Prop({
    required: false,
    default: false,
  })
  private readonly guestOnly!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly authenticated!: boolean;

  @Prop({
    required: false,
    default: false,
  })
  private readonly hideOnNoResult!: boolean;

  private dataSourceEmpty = true;

  private get display(): boolean {
    return (this.guestOnly && !this.authUser)
        || (this.authenticated && !!this.authUser)
        || (!this.guestOnly && !this.authenticated);
  }

  private get handleBaseUrl(): string {
    if (this.btnRoute) {
      let r = this.btnRoute;
      const matches = this.btnRoute.match(/(%[a-zA-Z-_.[0-9\]*]+%)/gs);
      if (matches) {
        matches.forEach((m) => {
          const prop = m.replaceAll('%', '')
            .trim();
          if ('data' in this.$props && this.data) {
            const newValue = deepGet(this.$props, prop);
            if (newValue) {
              r = r.replaceAll(m, newValue);
            }
          }
          if (this.$route.params[prop]) {
            r = r.replaceAll(m, this.$route.params[prop]);
          }
        });
      }
      return r;
    }
    return '';
  }

  private get insideTabItem(): boolean {
    return !!(this.widget
        && this.widget.parentWidget
        && this.widget.parentWidget.type === 'destination_tab_item_list_panel');
  }

  private get htmlResult(): string | null {
    let localHtmlResult = this.$t(this.html) as string;
    if (this.datasource) {
      const html = this.$t(this.html) as string;
      const matches = html.match(/({{[a-zA-Z-_.[0-9\]*]+}})/gs);
      this.dataSourceEmpty = !this.data;

      if (matches) {
        matches.forEach((m) => {
          const removeOpenBrackets = m.replaceAll('{', '')
            .trim();
          const removeCloseBrackets = removeOpenBrackets.replaceAll('}', '')
            .trim();

          let newValue = deepGet(this.data, removeCloseBrackets);
          if (!newValue && this.data && (this.data as unknown as []).length > 0) {
            newValue = deepGet(this.data[0], removeCloseBrackets);
          }

          if (!newValue) {
            newValue = '';
          }
          localHtmlResult = localHtmlResult.replaceAll(m, newValue);
        });
      }
    }
    if (this.datasource) {
      return this.dataSourceEmpty && this.hideOnNoResult ? '' : localHtmlResult;
    }
    return localHtmlResult;
  }

  private static copyElementAttributes(targetElement: HTMLElement, sourceElement: HTMLElement): void {
    const sourceAttributes = Array.prototype.slice.call(sourceElement.attributes);
    sourceAttributes
      .forEach((attribute) => targetElement.setAttribute(attribute.nodeName, attribute.nodeValue));
  }

  @Watch('isReadyToDisplay')
  renderScript(): void {
    this.$nextTick(() => {
      if (this.isReadyToDisplay
          && this.htmlResult
          && this.allowScript
          && this.$refs.htmlDump) {
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(this.htmlResult, 'text/html');
        const htmlDumpElement = this.$refs.htmlDump as HTMLDivElement;

        [...htmlDoc.body.children].forEach((htmlElement) => {
          if (htmlElement.tagName !== 'SCRIPT') {
            htmlDumpElement.appendChild(htmlElement);
          }
        });

        [...htmlDoc.scripts].forEach((userScriptElement) => {
          if (userScriptElement.hasAttribute('src')) {
            const newScriptElement = document.createElement('script');
            StaticHtmlWidget.copyElementAttributes(newScriptElement, userScriptElement);
            htmlDumpElement.appendChild(newScriptElement);
          } else {
            // eslint-disable-next-line no-eval
            window.eval(userScriptElement.innerText);
          }
        });
      }
    });
  }

  created(): void {
    this.setDataConfig();
  }

  private handleClicks(event: Event): void {
    let target = event.target as Element;
    while (target && target.tagName !== 'A') target = target.parentNode as Element;
    if (target && target.matches('.html-content a:not([href*=\'://\'])') && (target as HTMLLinkElement).href) {
      const {
        altKey,
        ctrlKey,
        metaKey,
        shiftKey,
        button,
        defaultPrevented,
      } = event as KeyboardEvent & { button: number | undefined };
      if (metaKey || altKey || ctrlKey || shiftKey) return;
      if (defaultPrevented) return;
      if (button !== undefined && button !== 0) return;
      if (target && target.getAttribute) {
        const linkTarget = target.getAttribute('target');
        if (linkTarget && /\b_blank\b/i.test(linkTarget)) return;
      }
      const url = new URL((target as HTMLLinkElement).href);
      let to = url.pathname;
      if (url.search && url.search.length) {
        to += url.search;
      }
      if (window.location.pathname !== to && event.preventDefault) {
        event.preventDefault();
        this.$router.push(to);
      }
    }
  }
}
